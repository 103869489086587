/* top level arrangement */
.view-wrapper {
    margin-left: 10px;
    margin-right: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
}

.column {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-base {
    align-items: baseline;
}

.align-end{
    align-items: flex-end;
}

.text-center{
    text-align: center;
}

.full-width{
    width: 100%
}

/* base styles */
h1{
 font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 font-size: 50px;
 letter-spacing: 0.2px;
 color: grey;
 margin-top: 50px;
}

h2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    letter-spacing: 0.2px;
    color: grey;
    margin-bottom: 20px;
}

h3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 25px;
    letter-spacing: 0.2px;
    color: #337ab7;
}

p, li {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: grey;
    margin-bottom: 20px;
}

ul {
    padding-bottom: 10px;
}

/* buttons */
.disabled, .disabled:hover {
    background-color: rgb(193, 191, 191);
    cursor: default;
}

button:focus{
    outline: none;
}

button.close{
    margin-right: 20px;
    font-weight: 400;
}

.play-btn{
    border: none;
    border-radius: 100%;
    background-color: transparent;
    color: lightgray;
    cursor: pointer;
    font-size: 18px;
    line-height: 0;
    font-weight: 400;
    width: 30px;
    height: 30px;
    position: absolute;
}

.play-btn:hover{
    background-color: #d2cfcf;
    color: white;
    box-shadow: 2px 2px 2px #a7a4a4;
    font-weight: 500;
    font-size: 21px;
}

.text-btn{
    border: none;
    border-radius: 100%;
    background-color: transparent;
    color: #337ab7;
    text-decoration: none;
}

.text-btn:hover{
    text-decoration: underline;
    color: #23527c
}


/* sections */
.instructions{
    margin: 50px
}

.open-instructions{
    margin-bottom: 50px
}

.closed-instructions{
    margin: 0px;
}

.instruction-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.about{
    font-weight: 300;
    margin-left: 5px;
    font-size: 17px;
}

/* checkbox */
[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
   }

[type=checkbox]{
    width: 20px;
    height: 20px;
    border: solid 1px #cccccc;
    margin-right: 8px;
    position: relative;
 }

 [type=checkbox]:checked::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: lightblue;
    position: absolute;
    top: 0px;
    left: 0px;
  }

/* responsive */
@media screen and (max-width: 1180px){
    button.close{
        margin-right: unset;
        position: fixed;
        top: 5%;
        left: calc(50% - 330px);
        opacity: 0.9;
        color: white;
    }
}

@media screen and (max-width: 690px){
    .row {
        flex-direction: column;
    }
    h1, h2, h3 {
        text-align: center;
    }
    .justify-center{
        align-items: center;
    }
    .instructions{
        width: 100%;
        margin: 40px 10px;
    }
    .open-instructions{
        margin-bottom: 40px
    }
    .instruction-row{
        width: 100%
    }
    button.close{
        margin-right: unset;
        position: fixed;
        top: 5%;
        left: 20px;
        opacity: 0.9;
        color: white;
    }
}
